<template>
  <Transition name="slide-fade">
    <section class="section-alert" v-if="showAlert" ref="sectionAlert" :class="{ 'password-invalid': passwordInvalid }">
      <table class="dist-alert">
        <tr>
          <td class="icon-alert-box">
            <img :src="iconAlert" alt="" class="icon-alert" />
          </td>
          <td class="message-notification">
            <p v-html="message"></p>
          </td>
          <td class="icon-close-box">
            <button class="delete" @click="close"></button>
          </td>
        </tr>
      </table>

      <div class="footer-alert" :style="styles" />
    </section>
  </Transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Notifications',
  data() {
    return {
      showAlert: false,
      footerColors: {
        success: '#a1ebc3',
        error: '#fca28d',
        info: '#80c2ea',
        warning: '#ffdd80',
      },
      sizeAlert: 0,
    };
  },
  created() {
    window.addEventListener('resize', () => this.getSizeAlert());
  },
  computed: {
    ...mapState('alertGeneral', ['type', 'message', 'duration', 'alertKey', 'passwordInvalid']),
    sizeFooter() {
      return this.sizeAlert * 0.8;
    },
    styles() {
      return { backgroundColor: this.footerColors[this.type], maxWidth: `${this.sizeFooter}px` };
    },
    iconAlert() {
      return `/icons/alert-${this.type}.svg`;
    },
  },
  watch: {
    alertKey() {
      this.setAlert();
    },
    message() {
      this.setAlert();
    },
  },
  methods: {
    setAlert() {
      this.showAlert = true;
      setTimeout(() => this.getSizeAlert(), 10);
      setTimeout(() => (this.showAlert = false), this.duration);
    },
    close() {
      this.showAlert = false;
    },
    getSizeAlert() {
      const SIZE = typeof this.$refs.sectionAlert !== 'undefined' ? this.$refs.sectionAlert.clientWidth : 300;
      this.sizeAlert = SIZE > 0 ? SIZE : 300;
    },
  },
};
</script>

<style>
.section-alert {
  background-color: white;
  border-radius: 10px;
  padding: 1rem 1rem 1rem 1rem;
  max-width: 380px;
  position: absolute;
  z-index: 300;
  right: 20px;
  top: 20px;
  box-shadow: 0 0 15px 0 grey;
}
.section-alert > .delete {
  right: 0.5rem;
  top: 0.5rem;
  position: absolute;
}
.icon-alert {
  height: 38px !important;
  width: 38px !important;
}
.footer-alert {
  height: 10px !important;
  margin: 0 0 -16px -16px;
  border-bottom-left-radius: 10px;
}
.message-notification {
  padding: 0 0 0 10px;
  font-family: 'Circular-Std-Book', serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.18px;
  color: #3c3c3c;
  display: table-cell;
  vertical-align: middle;
}
.dist-alert {
  margin: 0 10px 10px 0;
  width: 100%;
}
.icon-alert-box {
  width: 40px;
  display: table-cell;
  vertical-align: middle;
}
.icon-close-box {
  width: 10px;
}
@media (max-width: 768px) {
  .icon-alert {
    height: 20px !important;
    width: 20px !important;
  }
  .message-notification {
    font-size: 14px;
  }
  .icon-alert-box {
    width: 22px;
  }
}
@media (max-width: 420px) {
  .section-alert {
    left: 10px;
    right: 10px;
    top: 10px;
  }
  .icon-alert-box {
    vertical-align: top;
  }
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}
@media (min-width: 500px) {
  .password-invalid {
    max-width: 450px !important;
    width: 450px !important;
  }
}
</style>
